import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { parse, stringify } from 'query-string';
import Axios from 'axios';
import {
  Rate, Tooltip, Dropdown, Menu, Button, Input, Modal, Popover, Checkbox,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import LazyLoadDiv from '../../../common/components/LazyLoadDiv/index';
import Loader from '../../../common/components/Loader';
import {
  getHierarchyAction,
  getPisInHierarchyAction,
  getMorePisInHierarchyAction,
  getPisInHierarchyResetAction,
  removeChildCenterFromHierarchyAction,
  changeParentInHierarchyAction,
  changeParentInHierarchyResetAction,
  getParentInstitutionHierarchyResetAction,
  getParentInstitutionHierarchyAction,
} from './logic';
import { updatePiAction, updatePiResetAction } from '../../components/AddInvestigator/logic';
import { getDocProfileValuesAction } from '../DoctorProfile/logic';
import { getTemplate } from '../SearchBar/template';
import OutsideClick from '../../../common/components/OutsideClick';
import {
  getAutocompleteValuesAction,
  getAutocompleteValuesCancelAction,
  getAutocompleteValuesResetAction,
} from '../SearchBar/logic';
import { getCookie, hasPermissionForAction, isViewOnlyAccess } from '../../../utils';
import ReadMore from '../../../common/components/ReadMore';
import { updateSiteAction } from '../../components/AddCenterManuallyAddNew/logic';

const ParentCenterHierarchy = (props) => {
  const location = useLocation();
  const emojiRegex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
  const { Search } = Input;
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [getHierarchyData, setGetHierarchyData] = useState({});
  const [searchText, setSearchText] = useState('');
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [showTypeMore, setShowTypeMore] = useState(false);
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const [showPisOfCenter, setShowPisOfCenter] = useState('');
  const [fromVal, setFromVal] = useState(1);
  const [pisTotal, setPisTotal] = useState(0);
  const [showParentCenterSearch, setShowParentCenterSearch] = useState(false);
  const [showChildCenterSearch, setShowChildCenterSearch] = useState(false);
  const [showInvestigatorSearch, setShowInvestigatorSearch] = useState(false);
  const [showInvestigatorSearchForParent, setShowInvestigatorSearchForParent] = useState(false);
  const [showRemoveCenterConfirm, setShowRemoveCenterConfirm] = useState(false);
  const [showParentChangeConfirm, setShowParentChangeConfirm] = useState(false);
  const [showInvestigatorAddConfirm, setShowInvestigatorAddConfirm] = useState(false);
  const [showChangePOModal, setShowChangePOModal] = useState(false);
  const [autosuggestOptions, setAutosuggestOptions] = useState([]);
  const [pisInCenterList, setPisInCenterList] = useState([]);
  const [childCenterForAddition, setChildCenterForAddition] = useState({});
  const [parentForChildAddition, setParentForChildAddition] = useState({});
  const [childCenterForRemoval, setChildCenterForRemoval] = useState({});
  const [parentForChildRemoval, setParentForChildRemoval] = useState({});
  const [parentCenterForChange, setParentCenterForChange] = useState({});
  const [investigatorForAddition, setInvestigatorForAddition] = useState({});
  const [centerForInvestigatorRemoval, setCenterForInvestigatorRemoval] = useState({});
  const [investigatorForPOChange, setInvestigatorForPOChange] = useState({});
  const [oldParentCenterForChange, setOldParentCenterForChange] = useState('');
  const [centerForInvestigatorAddition, setCenterForInvestigatorAddition] = useState({});
  const parentInstitutionHierarchyData = useSelector(
    (store) => store.parentInstitutionHierarchyData,
  );
  const updateSiteResponse = useSelector((store) => store.updateSiteResponse);
  const pisInHierarchyData = useSelector((store) => store.pisInHierarchyData);
  const autoCompleteData = useSelector((state) => state.autoCompleteData);
  const removeChildCenterFromHierarchyResponse = useSelector(
    (state) => state.removeChildCenterFromHierarchyResponse,
  );
  const changeParentInHierarchyResponse = useSelector(
    (state) => state.changeParentInHierarchyResponse,
  );
  const investigatorProfileData = useSelector((state) => state.docProfile);
  const updatePiResponse = useSelector((state) => state.updatePiResponse);
  const [checkedValueList, setCheckedValueList] = useState([
    'total_popis',
    'is_invalid',
    'quality',
    'alliance_type',
    'site_reliability_class',
    'site_reliability_score',
    'center_sims_ids',
    'survey_sent',
    'survey_responded',
    'survey_interested',
    'survey_not_interested',
    'startup_average',
    'startup_factor',
    'recruitment_factor',
    'enrollment_subjects',
    'ongoing_pxl_studies',
    'closed_pxl_studies',
    'industry_studies',
    'pxl_studies',
  ]);
  const [checkedHeaderList, setCheckedHeaderList] = useState([
    '# PIs',
    'Status',
    'Alliance Type',
    'Quality',
    'Reliability',
    'Reliability Score',
    'Center ID',
    '#Survey Sent',
    '#Survey Responded',
    '#Survey Interested',
    '#Survey Not Interested',
    'Startup Months',
    'Startup Factor',
    'Recruitment Factor',
    'Enrolled Subjects',
    'Ongoing PXL # Studies',
    'Closed PXL # Studies',
    '# Industry Studies',
    '# PXL Studies',
  ]);
  const [hidePIsofCenter, setHidePIsofCenter] = useState([]);

  useEffect(() => {
    return () => {
      dispatch(getParentInstitutionHierarchyResetAction());
      dispatch(getPisInHierarchyResetAction());
    };
  }, []);

  useEffect(() => {
    if (parse(location.search).id) {
      setHidePIsofCenter([]);
      dispatch(
        getParentInstitutionHierarchyAction({
          id: parse(location.search).id,
          alliance_type: props.allianceType,
          filters: JSON.stringify({
            date: props.dateFilter,
          }),
        }),
      );
    }
  }, [parse(location.search).id, props.allianceType]);

  useEffect(() => {
    if (autoCompleteData.flag) {
      setAutosuggestOptions(autoCompleteData.data);
    }
  }, [JSON.stringify(autoCompleteData)]);

  useEffect(() => {
    if (parentInstitutionHierarchyData.flag) {
      setGetHierarchyData(parentInstitutionHierarchyData.data);

      if (showPisOfCenter && updatePiResponse.flag) {
        setFromVal(1);
        dispatch(getPisInHierarchyResetAction());
        dispatch(
          getPisInHierarchyAction({
            center_id: showPisOfCenter,
            from: 1,
            size: 10,
          }),
        );
        dispatch(updatePiResetAction());
      }
    }
  }, [JSON.stringify(parentInstitutionHierarchyData)]);

  useEffect(() => {
    if (pisInHierarchyData.flag) {
      setPisInCenterList(pisInHierarchyData.data.data);
      setPisTotal(pisInHierarchyData.data.total);
    }
  }, [JSON.stringify(pisInHierarchyData)]);

  useEffect(() => {
    if (updateSiteResponse.flag) {
      setSearchText('');
      setIsConfirmationModalOpen(false);
      setChildCenterForAddition({});
      setParentForChildAddition({});
      setShowChildCenterSearch(false);
      dispatch(
        getParentInstitutionHierarchyAction({
          id: parse(location.search).id,
          alliance_type: props.allianceType,
          filters: JSON.stringify({
            date: props.dateFilter,
          }),
        }),
      );
    }
  }, [JSON.stringify(updateSiteResponse)]);

  useEffect(() => {
    if (removeChildCenterFromHierarchyResponse.flag) {
      setShowRemoveCenterConfirm(false);
      setChildCenterForRemoval({});
      setParentForChildRemoval({});
      dispatch(
        getParentInstitutionHierarchyAction({
          id: parse(location.search).id,
          alliance_type: props.allianceType,
          filters: JSON.stringify({
            date: props.dateFilter,
          }),
        }),
      );
    }
  }, [JSON.stringify(removeChildCenterFromHierarchyResponse)]);

  //
  useEffect(() => {
    dispatch(
      getParentInstitutionHierarchyAction({
        id: parse(location.search).id,
        alliance_type: props.allianceType,
        filters: JSON.stringify({
          date: props.dateFilter,
        }),
      }),
    );
  }, [props.dateFilter])

  useEffect(() => {
    if (changeParentInHierarchyResponse.flag) {
      setSearchText('');
      setShowParentCenterSearch(false);
      setShowParentChangeConfirm(false);
      setParentCenterForChange({});
      setOldParentCenterForChange('');
      dispatch(
        getHierarchyAction({
          id: parse(location.search).id,
          type: props.type,
        }),
      );
      dispatch(changeParentInHierarchyResetAction());
    }
  }, [JSON.stringify(changeParentInHierarchyResponse)]);

  useEffect(() => {
    if (updatePiResponse.flag) {
      setShowChangePOModal(false);
      setCenterForInvestigatorRemoval({});
      setInvestigatorForPOChange({});
      setCenterForInvestigatorAddition({});
      setInvestigatorForAddition({});
      setShowInvestigatorAddConfirm(false);
      setSearchText('');
      setShowInvestigatorSearch(false);
      setShowInvestigatorSearchForParent(false);
      dispatch(
        getParentInstitutionHierarchyAction({
          id: parse(location.search).id,
          alliance_type: props.allianceType,
          filters: JSON.stringify({
            date: props.dateFilter,
          }),
        }),
      );
      dispatch(
        getHierarchyAction({
          id: parse(location.search).id,
          type: props.type,
        }),
      );
    }
  }, [JSON.stringify(updatePiResponse)]);

  const onChangeInput = (e, type) => {
    dispatch(getAutocompleteValuesCancelAction());
    if (e.target.value && e.target.value.trim().length <= 2) {
      setDisplayDropdown(true);
      setShowTypeMore(true);
      setSearchText(e.target.value);
      dispatch(getAutocompleteValuesResetAction());
    } else if (
      e.target.value
      && e.target.value.trim().length > 2
      && !emojiRegex.test(e.target.value)
    ) {
      setShowTypeMore(false);
      setDisplayDropdown(true);
      setSearchText(e.target.value);
      dispatch(
        getAutocompleteValuesAction({
          query: e.target.value.trim(),
          dataset: type,
          filters: JSON.stringify({ parent_flag: false }),
        }),
      );
    } else {
      setDisplayDropdown(false);
      setSearchText('');
      if (centerForInvestigatorAddition && showChangePOModal) {
        setCenterForInvestigatorAddition({});
      }
    }
  };

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  const autoSuggestionItemClicked = (item) => {
    if (showChangePOModal) {
      setCenterForInvestigatorAddition(item);
      setDisplayDropdown(false);
      setSearchText(item.name);
    } else if (showInvestigatorSearch || showInvestigatorSearchForParent) {
      dispatch(getDocProfileValuesAction({ id: item.id, dataset: 'investigators' }));
      setInvestigatorForAddition(item);
      setShowInvestigatorAddConfirm(true);
    } else if (showParentCenterSearch) {
      setParentCenterForChange(item);
      setShowParentChangeConfirm(true);
    } else {
      setChildCenterForAddition(item);
      setIsConfirmationModalOpen(true);
    }
  };

  const isValueAllowed = (key) => {
    return !notAllowed.includes(key);
  };

  const renderAutosuggestOptions = () => {
    if (autoCompleteData.flag && autosuggestOptions.length) {
      return autosuggestOptions.map((itm, index) => {
        return (
          <div
            className="search-dropdown-result-list-card"
            role="presentation"
            key={index}
            onClick={() => autoSuggestionItemClicked(itm)}
          >
            {getTemplate(itm.type, itm, index)}
          </div>
        );
      });
    }
    if (autoCompleteData.flag && autosuggestOptions.length === 0) {
      return (
        <div className="autosuggest-no-data-msg">
          <span>No matches found, click &#39;Search&#39; to initiate plain text search.</span>
        </div>
      );
    }
    if (showTypeMore) {
      return (
        <div className="autosuggest-no-data-msg">
          Please type at least 3 characters to show autosuggested options or initiate search.
        </div>
      );
    }
    return null;
  };

  const onHeaderScroll = () => {
    const source = document.getElementById('table-column-header1');
    const targets = document.querySelectorAll('.table-column-body');
    targets.forEach((item) => {
      item.scrollLeft = source.scrollLeft;
    });
  };

  const onRowScroll = (e) => {
    const source = document.getElementById('table-column-header1');
    const targets = document.querySelectorAll('.table-column-body');
    targets.forEach((item) => {
      item.scrollLeft = e.target.scrollLeft;
    });
    source.scrollLeft = e.target.scrollLeft;
  };

  const handleSiteMenuClick = (e, record) => {
    if (e.key === '1') {
      if (hidePIsofCenter.indexOf(record.id) !== -1) {
        onCenterClick(record);
      }
      setParentForChildAddition(record);
      setSearchText('');
      setShowChildCenterSearch(true);
      setShowInvestigatorSearch(false);
      setShowInvestigatorSearchForParent(false);
      setShowParentCenterSearch(false);
    }
    if (e.key === '2') {
      if (showPisOfCenter !== record.id) {
        onCenterClick(record);
      }
      setCenterForInvestigatorAddition(record);
      setSearchText('');
      setShowInvestigatorSearchForParent(true);
      setShowChildCenterSearch(false);
      setShowParentCenterSearch(false);
      setShowInvestigatorSearch(false);
    }
  };

  const siteDataMenu = (record) => (
    <Menu
      onClick={(e) => handleSiteMenuClick(e, record)}
      className="project-detail-cardmenu-dropdown"
      items={[
        {
          key: '1',
          label: 'Add child center',
          className: 'project-details-dropdown-icon comparision-dropdown-icon',
        },
      ]}
    />
  );

  const handlePrimaryOrgMenuClick = (e, record, rcd) => {
    if (e.key === '1') {
      setParentForChildRemoval(rcd);
      setChildCenterForRemoval(record);
      setShowRemoveCenterConfirm(true);
    }
    if (e.key === '2') {
      if (showPisOfCenter !== record.id) {
        onCenterClick(record);
      }
      setCenterForInvestigatorAddition(record);
      setSearchText('');
      setShowInvestigatorSearch(true);
      setShowInvestigatorSearchForParent(false);
      setShowChildCenterSearch(false);
      setShowParentCenterSearch(false);
    }
  };

  const primaryOrgDataMenu = (record, rcd) => (
    <Menu
      onClick={(e) => handlePrimaryOrgMenuClick(e, record, rcd)}
      className="project-detail-cardmenu-dropdown"
      items={[
        // parse(location.search).id !== record.id && record.id !== rcd.id
        //   ? {
        //     key: '1',
        //     label: 'Remove organization from hierarchy',
        //     className:
        //         'project-details-dropdown-icon comparision-dropdown-icon remove-org-from-hierarchy',
        //   }
        //   : null,
        {
          key: '2',
          label: 'Add investigator',
          className: 'project-details-dropdown-icon comparision-dropdown-icon',
        },
      ]}
    />
  );

  const handleRemoveCenterCancel = () => {
    setParentForChildRemoval({});
    setChildCenterForRemoval({});
    setShowRemoveCenterConfirm(false);
  };

  const onRemoveCenterClick = () => {
    dispatch(
      removeChildCenterFromHierarchyAction({
        params: {
          parent_id: parentForChildRemoval.id,
          center_id: childCenterForRemoval.id,
        },
      }),
    );
  };

  const onAddCenterClick = () => {
    const updateTemp = {
      name: {
        old: childCenterForAddition.name,
        new: childCenterForAddition.name,
        flag: false,
      },
      alliance_type: {
        old: '',
        new: props.allianceType,
        flag: true,
      },
      alliance_member_id: {
        old: '',
        new: parentForChildAddition.id,
        flag: true,
      },
    }
    dispatch(updateSiteAction({
      params: {
        site_id: childCenterForAddition.id,
        direct_edit: true,
        duplicate_update: false,
      },
      body: updateTemp,
    }))
  };

  const onChangeParentClick = () => {
    dispatch(
      changeParentInHierarchyAction({
        center_id: props.data.data.id,
        center_name: props.data.data.name,
        id_new: parentCenterForChange.id,
        id_old: oldParentCenterForChange,
        parent_name_new: parentCenterForChange.name,
      }),
    );
  };

  const onAddInvestigator = () => {
    const updateTemp = {
      alliance_member_id: {
        old: '',
        new: parse(location.search).id || '',
        flag: true,
      },
      alliance_type: {
        old: '',
        new: centerForInvestigatorAddition.alliance_type || '',
        flag: true,
      },
      primary_organization_id: {
        old: investigatorProfileData.data.primary_organization_id,
        new: centerForInvestigatorAddition.id ? centerForInvestigatorAddition.id : '',
        flag: true,
      },
    };
    dispatch(
      updatePiAction({
        params: {
          pi_id: investigatorForAddition.id,
          direct_edit: true,
        },
        body: updateTemp,
      }),
    );
  };

  const onChangePODone = () => {
    const updateTemp = {
      primary_organization_id: {
        old: centerForInvestigatorRemoval.id ? centerForInvestigatorRemoval.id : '',
        new: centerForInvestigatorAddition.id,
        flag: true,
      },
    };
    dispatch(
      updatePiAction({
        params: {
          pi_id: investigatorForPOChange.id,
          direct_edit: true,
        },
        body: updateTemp,
      }),
    );
  };

  const onCenterClick = (record) => {
    if (record.id && showPisOfCenter !== record.id) {
      setShowPisOfCenter(record.id);
    } else {
      setShowPisOfCenter('');
    }
    const temp = [...hidePIsofCenter];
    if (temp.indexOf(record.id) === -1) {
      temp.push(record.id);
    } else {
      temp.splice(temp.indexOf(record.id), 1);
    }
    setHidePIsofCenter(temp);
  };

  const loadMoreRows = (record) => {
    dispatch(
      getMorePisInHierarchyAction({
        center_id: record.id ? record.id : null,
        from: fromVal + 1,
        size: 10,
      }),
    );
    setFromVal(fromVal + 1);
  };

  const onChange = (e, checkedValues, checkedHeader) => {
    if (e.target.checked) {
      setCheckedValueList([...checkedValueList, checkedValues]);
      setCheckedHeaderList([...checkedHeaderList, checkedHeader]);
    } else {
      const newCheckedVal = checkedValueList.filter((itm) => itm !== checkedValues);
      const newCheckedHead = checkedHeaderList.filter((itm) => itm !== checkedHeader);
      setCheckedValueList(newCheckedVal);
      setCheckedHeaderList(newCheckedHead);
    }
  };

  const filterHeader = [
    {
      header_name: '# PIs',
      key: 'total_popis',
    },
    {
      header_name: 'Status',
      key: 'is_invalid',
    },
    {
      header_name: 'Alliance Type',
      key: 'alliance_type',
    },
    {
      header_name: 'Quality',
      key: 'quality',
    },
    {
      header_name: 'Reliability',
      key: 'site_reliability_class',
    },
    {
      header_name: 'Reliability Score',
      key: 'site_reliability_score',
    },
    {
      header_name: 'Center ID',
      key: 'center_sims_ids',
    },
    {
      header_name: '#Survey Sent',
      key: 'survey_sent',
    },
    {
      header_name: '#Survey Responded',
      key: 'survey_responded',
    },
    {
      header_name: '#Survey Interested',
      key: 'survey_interested',
    },
    {
      header_name: '#Survey Not Interested',
      key: 'survey_not_interested',
    },
    {
      header_name: 'Startup Months',
      key: 'startup_average',
    },
    {
      header_name: 'Startup Factor',
      key: 'startup_factor',
    },
    {
      header_name: 'Recruitment Factor',
      key: 'recruitment_factor',
    },
    {
      header_name: 'Enrolled Subjects',
      key: 'enrollment_subjects',
    },
    {
      header_name: 'Ongoing PXL # Studies',
      key: 'ongoing_pxl_studies',
    },
    {
      header_name: 'Closed PXL # Studies',
      key: 'closed_pxl_studies',
    },
    {
      header_name: '# Industry Studies',
      key: 'industry_studies',
    },
    {
      header_name: '# PXL Studies',
      key: 'pxl_studies',
    },
  ];
  const [notAllowed, setNotAllowed] = useState([]);
  const [allowedHeader, setAllowedHeader] = useState([]);

  const onApplyShowHideClick = () => {
    setVisible(false);
    const tempHeader = [
      '# PIs',
      'Status',
      'Alliance Type',
      'Quality',
      'Reliability',
      'Reliability Score',
      'Center ID',
      '#Survey Sent',
      '#Survey Responded',
      '#Survey Interested',
      '#Survey Not Interested',
      'Startup Months',
      'Startup Factor',
      'Recruitment Factor',
      'Enrolled Subjects',
      'Ongoing PXL # Studies',
      'Closed PXL # Studies',
      '# Industry Studies',
      '# PXL Studies',
    ];
    const tempValues = [
      'total_popis',
      'is_invalid',
      'alliance_type',
      'quality',
      'site_reliability_class',
      'site_reliability_score',
      'center_sims_ids',
      'survey_sent',
      'survey_responded',
      'survey_interested',
      'survey_not_interested',
      'startup_average',
      'startup_factor',
      'recruitment_factor',
      'enrollment_subjects',
      'ongoing_pxl_studies',
      'closed_pxl_studies',
      'industry_studies',
      'pxl_studies',
    ];
    setNotAllowed(tempValues.filter((itm) => checkedValueList.indexOf(itm) === -1));
    setAllowedHeader(tempHeader.filter((itm) => checkedHeaderList.indexOf(itm) === -1));
  };

  const onApplyShowHideCancelClick = () => {
    setVisible(false);
  };

  const headers = [
    {
      name: '# PIs',
      description:
        '# of investigators directly affiliated to the parent institution, child centers of parent institution and alliance network of parent institution',
    },
    {
      name: 'Status',
      description: 'Status of investigator or center',
    },
    {
      name: 'Alliance Type',
      description: 'Current alliance type of investigator or center',
    },
    {
      name: 'Quality',
      description: 'Quality rating for investigators',
    },
    {
      name: 'Reliability',
      description:
        'Reliability rating for investigators / centers based on the planned and actual recruitment',
    },
    {
      name: 'Reliability Score',
      description:
        'Reliability score for investigators / centers based on the planned and actual recruitment',
    },
    {
      name: 'Center ID',
      description: 'SIMS ID for the center',
    },
    {
      name: '#Survey Sent',
      description: '# of Surveys sent to a given investigator or center',
    },
    {
      name: '#Survey Responded',
      description: '# of Surveys responded by a given investigator or center',
    },
    {
      name: '#Survey Interested',
      description:
        '# of Surveys where the investigator or center responded showing interest in participating for a study',
    },
    {
      name: '#Survey Not Interested',
      description:
        '# of Surveys where the investigator or center declined interest in participating for a study',
    },
    {
      name: 'Startup Months',
      description: 'Average start up months for prior studies',
    },
    {
      name: 'Startup Factor',
      description: 'Average start up factor for prior studies',
    },
    {
      name: 'Recruitment Factor',
      description: 'Recruitment factor based on recruitment history of the investigator or center',
    },
    {
      name: 'Enrolled Subjects',
      description: 'Total # of subjects enrolled in prior studies',
    },
    {
      name: 'Ongoing PXL # Studies',
      description: '# of PXL studies which are ongoing ',
    },
    {
      name: 'Closed PXL # Studies',
      description: '# of PXL studies which are closed',
    },
    {
      name: '# Industry Studies',
      description: '# industry studies',
    },
    {
      name: '# PXL Studies',
      description: '# PXL studies',
    },
  ];

  const renderHeader = () => {
    return (
      <div
        id="table-column-header1"
        className="table-column-sec table-column-header"
        onScroll={onHeaderScroll}
      >
        {headers
          .filter((itm) => !allowedHeader.includes(itm.name))
          .map((item) => (
            <div className="filter-sel-data-value center">
              <span>{item.name} </span>
              <span>
                <Tooltip title={item.description}>
                  <InfoCircleOutlined />
                </Tooltip>
              </span>
            </div>
          ))}
      </div>
    );
  };

  const renderHirerchyData = (record, recordIndex) => {
    return (
      <div className="country-runanalysis-wrap country-runanalysis-wrap-hirerchy" key={recordIndex}>
        <div className="country-name">
          <span className="country-name-title">
            <a
              title={record.country}
              target="_blank"
              href={`/search/countryprofile?${stringify({
                country: record.country,
                type: 'COUNTRY',
              })}`}
              rel="noreferrer noopener"
            >
              {record.country}
            </a>
          </span>
        </div>
        {record.parent_institutes
          && record.parent_institutes.map((rcd, indexv) => (
            <div className="parent-site-wrap" key={indexv}>
              {showParentCenterSearch && rcd.id === oldParentCenterForChange ? (
                <div className="run-query-content-section level-header-section level-two-header-section">
                  <div className="run-query-content-card card-country-content">
                    <div className="card-content card-country-content-header">
                      <div className="body-analysis-section level-two-header-section">
                        <div className="leftside-column">
                          <>
                            <div className="search-bar search-wrap hierachy-searchbar">
                              <div className="search-bar-wrap">
                                <div
                                  className={`search-box ${
                                    searchText.trim().length >= 3 ? 'activesearch' : ''
                                  }`}
                                >
                                  <Search
                                    placeholder="Search for Center"
                                    allowClear
                                    value={searchText}
                                    loading={autoCompleteData.loading}
                                    enterButton={(
                                      <Button disabled={searchText.trim().length <= 2}>
                                        <span className="search-icon" />
                                      </Button>
                                    )}
                                    size="large"
                                    maxLength="256"
                                    onChange={(e) => onChangeInput(e, 'centers')}
                                  />
                                </div>
                              </div>
                              <div
                                className="close-edit-search"
                                role="presentation"
                                onClick={() => {
                                  setSearchText('');
                                  setShowParentCenterSearch(false);
                                  setOldParentCenterForChange('');
                                }}
                              >
                                Cancel
                              </div>
                              <div className="search-dropdown-result-content">
                                {displayDropdown ? (
                                  <OutsideClick
                                    ignoreClickWithinClass="search-bar"
                                    onClickOutside={() => {
                                      setDisplayDropdown(false);
                                    }}
                                  >
                                    <div className="search-dropdown-result">
                                      <div className="search-dropdown-result-list">
                                        <Loader
                                          loading={autoCompleteData.loading}
                                          error={autoCompleteData.error}
                                        >
                                          {renderAutosuggestOptions()}
                                        </Loader>
                                      </div>
                                    </div>
                                  </OutsideClick>
                                ) : null}
                              </div>
                            </div>
                          </>
                        </div>
                        <div className="table-column">
                          <div className="table-column-sec table-column-body" />
                        </div>
                        <div className="lastside-column" />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="run-query-content-section level-header-section level-two-header-section">
                  <div className="run-query-content-card card-country-content">
                    <div className="card-content card-country-content-header card-country-content-header-child-center">
                      <div className="body-analysis-section level-two-header-section">
                        <div
                          className={`leftside-column ${
                            hidePIsofCenter.indexOf(rcd.id) === -1
                              ? 'dropdown-open'
                              : 'dropdown-close'
                          }`}
                        >
                          {(rcd.id === parse(location.search).id
                            || rcd.id !== parse(location.search).id)
                          && parse(location.search).currentTab !== 'parent_centers' ? (
                            <div
                              className="parent-edit-icon"
                              role="presentation"
                              onClick={() => {
                                setSearchText('');
                                setShowParentCenterSearch(true);
                                setShowChildCenterSearch(false);
                                setShowInvestigatorSearch(false);
                                setShowInvestigatorSearchForParent(false);
                                setOldParentCenterForChange(rcd.id);
                              }}
                            />
                            ) : null}
                          <a
                            className={`card-content-title ${
                              props.type === 'centers' && parse(location.search).id === rcd.id
                                ? 'text-bold'
                                : ''
                            }`}
                            title={rcd.name}
                            target="_blank"
                            href={`/search/deepdives?${stringify({
                              query: rcd.name,
                              id: rcd.id,
                              type: rcd?.parent_flag ? 'SITE_Parent' : 'SITE',
                              currentTab: rcd?.parent_flag ? 'parent_centers' : 'centers',
                            })}`}
                            rel="noreferrer noopener"
                          >
                            {rcd.name}
                          </a>

                          <div
                            className={
                       rcd?.center_quality === 'Compliance not preventing participation'
                       || rcd?.center_quality === 'Approved with Warning'
                         ? 'compliance-flag-icon preventing'
                         : rcd?.center_quality === 'Compliance preventing participation'
                           || rcd?.center_quality === 'DNU'
                           ? 'compliance-flag-icon not-preventing'
                           : rcd?.center_quality === 'Archived issue'
                             ? 'compliance-flag-icon archive-issue'
                             : 'heading'
                     }
                          />

                          {props.type === 'centers' && rcd.pis.length ? (
                            <span
                              className="dropdown-arrow"
                              role="presentation"
                              onClick={() => onCenterClick(rcd)}
                            />
                          ) : null}
                        </div>
                        <div className="table-column">
                          <div
                            className="table-column-sec table-column-body"
                            onScroll={onRowScroll}
                          >
                            {isValueAllowed('total_popis') && (
                              <div className="filter-sel-data-value enrolled-subjects">
                                {rcd.total_popis}
                              </div>
                            )}
                            {isValueAllowed('is_invalid') && (
                              <div className="filter-sel-data-value enrolled-subjects">

                                {rcd.is_invalid === false ? 'Valid' : 'Invalid'}
                              </div>
                            )}
                            {isValueAllowed('alliance_type') && (
                              <div className="filter-sel-data-value enrolled-subjects">

                                {rcd.alliance_type ? rcd.alliance_type : '-'}
                              </div>
                            )}
                            {isValueAllowed('quality') && (
                              <div className="filter-sel-data-value quality">
                                <Rate count={3} disabled value={rcd.quality} />
                              </div>
                            )}
                            {isValueAllowed('site_reliability_class') && (
                              <div className="filter-sel-data-value reliability">
                                {rcd.site_reliability_class}
                              </div>
                            )}
                            {isValueAllowed('site_reliability_score') && (
                              <div className="filter-sel-data-value reliability">
                                {rcd.site_reliability_score.toFixed(2)}
                              </div>
                            )}
                            {isValueAllowed('center_sims_ids') && (
                              <div className="filter-sel-data-value reliability">
                                {rcd?.center_sims_ids.length ? (
                                  <ReadMore type="text" limit={30}>
                                    {rcd?.center_sims_ids.join(',')}
                                  </ReadMore>
                                ) : (
                                  '-'
                                )}
                              </div>
                            )}
                            {isValueAllowed('survey_sent') && (
                              <div className="filter-sel-data-value enrolled-subjects">
                                {rcd.survey_sent}
                              </div>
                            )}
                            {isValueAllowed('survey_responded') && (
                              <div className="filter-sel-data-value enrolled-subjects">
                                {rcd.survey_responded}
                              </div>
                            )}
                            {isValueAllowed('survey_interested') && (
                              <div className="filter-sel-data-value enrolled-subjects">
                                {rcd.survey_interested}
                              </div>
                            )}
                            {isValueAllowed('survey_not_interested') && (
                              <div className="filter-sel-data-value enrolled-subjects">
                                {rcd.survey_not_interested}
                              </div>
                            )}
                            {isValueAllowed('startup_average') && (
                              <div className="filter-sel-data-value enrolled-subjects">
                                {rcd.startup_average.toFixed(2)}
                              </div>
                            )}
                            {isValueAllowed('startup_factor') && (
                              <div className="filter-sel-data-value enrolled-subjects">
                                {rcd.startup_factor.toFixed(2)}
                              </div>
                            )}
                            {isValueAllowed('recruitment_factor') && (
                              <div className="filter-sel-data-value enrolled-subjects">
                                {rcd.recruitment_factor.toFixed(2)}
                              </div>
                            )}
                            {isValueAllowed('enrollment_subjects') && (
                              <div className="filter-sel-data-value enrolled-subjects">
                                {rcd.enrollment_subjects}
                              </div>
                            )}
                            {isValueAllowed('ongoing_pxl_studies') && (
                              <div className="filter-sel-data-value enrolled-subjects">
                                {rcd.ongoing_pxl_studies}
                              </div>
                            )}
                            {isValueAllowed('closed_pxl_studies') && (
                              <div className="filter-sel-data-value enrolled-subjects">
                                {rcd.closed_pxl_studies}
                              </div>
                            )}
                            {isValueAllowed('industry_studies') && (
                              <div className="filter-sel-data-value enrolled-subjects">
                                {rcd.industry_studies}
                              </div>
                            )}
                            {isValueAllowed('pxl_studies') && (
                            <div className="filter-sel-data-value enrolled-subjects">
                              {rcd.pxl_studies}
                            </div>
                            )}

                          </div>
                        </div>
                        {
                          !isViewOnlyAccess() && (
                            <div className="lastside-column">
                              {props.type === 'centers'
                            && ((props.data
                              && props.data.data
                              && props.data.data.parent_flag
                              && parse(location.search).id === rcd.id)
                              || (props.data
                                && props.data.data
                                && !props.data.data.parent_flag
                                && parse(location.search).id !== rcd.id)) ? (
                                  <Dropdown overlay={() => siteDataMenu(rcd)} disabled={!hasPermissionForAction('edit_center_hierarchy')} trigger={['click']}>
                                    <Button
                                      className="accordion-menu"
                                      role="presentation"
                                      onClick={(e) => {
                                        e.preventDefault();
                                      }}
                                    >
                                      <Tooltip title="Kebab menu">
                                        <div className="accordion-menu-icon" />
                                      </Tooltip>
                                    </Button>
                                  </Dropdown>
                                ) : null}
                            </div>
                          )
                        }
                      </div>
                    </div>
                    {showChildCenterSearch && rcd.id === parentForChildAddition.id ? (
                      <div className="run-query-content-section run-query-content-section-child">
                        <div className="run-query-content-card card-country-content">
                          <div className="card-content card-country-content-header">
                            <div className="body-analysis-section">
                              <div className="leftside-column">
                                <>
                                  <div className="search-bar search-wrap hierachy-searchbar">
                                    <div className="search-bar-wrap">
                                      <div
                                        className={`search-box ${
                                          searchText.trim().length >= 3 ? 'activesearch' : ''
                                        }`}
                                      >
                                        <Search
                                          placeholder="Search for Center"
                                          allowClear
                                          value={searchText}
                                          loading={autoCompleteData.loading}
                                          enterButton={(
                                            <Button disabled={searchText.trim().length <= 2}>
                                              <span className="search-icon" />
                                            </Button>
                                          )}
                                          size="large"
                                          maxLength="256"
                                          onChange={(e) => onChangeInput(e, 'centers')}
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className="close-edit-search"
                                      role="presentation"
                                      onClick={() => {
                                        setSearchText('');
                                        setShowChildCenterSearch(false);
                                        setParentForChildAddition({});
                                      }}
                                    >
                                      Cancel
                                    </div>
                                    <div className="search-dropdown-result-content">
                                      {displayDropdown ? (
                                        <OutsideClick
                                          ignoreClickWithinClass="search-bar"
                                          onClickOutside={() => {
                                            setDisplayDropdown(false);
                                          }}
                                        >
                                          <div className="search-dropdown-result">
                                            <div className="search-dropdown-result-list">
                                              <Loader
                                                loading={autoCompleteData.loading}
                                                error={autoCompleteData.error}
                                              >
                                                {renderAutosuggestOptions()}
                                              </Loader>
                                            </div>
                                          </div>
                                        </OutsideClick>
                                      ) : null}
                                    </div>
                                  </div>
                                </>
                              </div>
                              <div className="table-column">
                                <div className="table-column-sec table-column-body" />
                              </div>
                              <div className="lastside-column" />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {rcd.pis && rcd.pis.length && hidePIsofCenter.indexOf(rcd.id) === -1 ? (
                      <div className="pi-name-content">
                        {rcd.pis.map((piitm, idy) => {
                          return (
                            <div className="pi-content" key={idy}>
                              <div className="body-analysis-section">
                                <div className="leftside-column">
                                  <a
                                    className={`pi-content-title ${
                                      parse(location.search).id === piitm.id ? 'text-bold' : ''
                                    }`}
                                    title={piitm.name}
                                    target="_blank"
                                    href={`/search/investigatorprofile?${stringify({
                                      query: piitm.name,
                                      id: piitm.id,
                                      type: 'PI',
                                    })}`}
                                    rel="noreferrer noopener"
                                  >
                                    {piitm.name}
                                  </a>
                                </div>
                                <div className="table-column">
                                  <div
                                    className="table-column-sec table-column-body"
                                    onScroll={onRowScroll}
                                  >
                                    {isValueAllowed('total_popis') && (
                                      <div className="filter-sel-data-value enrolled-subjects">
                                        {piitm.total_popis}
                                      </div>
                                    )}
                                    {isValueAllowed('is_invalid') && (
                                      <div className="filter-sel-data-value enrolled-subjects">

                                        {piitm.is_invalid === false ? 'Valid' : 'Invalid'}
                                      </div>
                                    )}
                                    {isValueAllowed('alliance_type') && (
                                      <div className="filter-sel-data-value enrolled-subjects">

                                        {piitm.alliance_type ? piitm.alliance_type : '-'}
                                      </div>
                                    )}
                                    {isValueAllowed('quality') && (
                                      <div className="filter-sel-data-value quality">
                                        <Rate count={3} disabled value={piitm.quality} />
                                      </div>
                                    )}
                                    {isValueAllowed('site_reliability_class') && (
                                      <div className="filter-sel-data-value reliability">
                                        {piitm.site_reliability_class}
                                      </div>
                                    )}
                                    {isValueAllowed('site_reliability_score') && (
                                      <div className="filter-sel-data-value reliability">
                                        {piitm.site_reliability_score.toFixed(2)}
                                      </div>
                                    )}
                                    {isValueAllowed('center_sims_ids') && (
                                      <div className="filter-sel-data-value reliability">
                                        {piitm?.center_sims_ids.length ? (
                                          <ReadMore type="text" limit={30}>
                                            {piitm?.center_sims_ids.join(',')}
                                          </ReadMore>
                                        ) : (
                                          '-'
                                        )}
                                      </div>
                                    )}
                                    {isValueAllowed('survey_sent') && (
                                      <div className="filter-sel-data-value enrolled-subjects">
                                        {piitm.survey_sent}
                                      </div>
                                    )}
                                    {isValueAllowed('survey_responded') && (
                                      <div className="filter-sel-data-value enrolled-subjects">
                                        {piitm.survey_responded}
                                      </div>
                                    )}
                                    {isValueAllowed('survey_interested') && (
                                      <div className="filter-sel-data-value enrolled-subjects">
                                        {piitm.survey_interested}
                                      </div>
                                    )}
                                    {isValueAllowed('survey_not_interested') && (
                                      <div className="filter-sel-data-value enrolled-subjects">
                                        {piitm.survey_not_interested}
                                      </div>
                                    )}
                                    {isValueAllowed('startup_average') && (
                                      <div className="filter-sel-data-value enrolled-subjects">
                                        {piitm.startup_average.toFixed(2)}
                                      </div>
                                    )}
                                    {isValueAllowed('startup_factor') && (
                                      <div className="filter-sel-data-value enrolled-subjects">
                                        {piitm.startup_factor.toFixed(2)}
                                      </div>
                                    )}
                                    {isValueAllowed('Recruitment Factor') && (
                                      <div className="filter-sel-data-value enrolled-subjects">
                                        {piitm.recruitment_factor.toFixed(2)}
                                      </div>
                                    )}
                                    {isValueAllowed('enrollment_subjects') && (
                                      <div className="filter-sel-data-value enrolled-subjects">
                                        {piitm.enrollment_subjects}
                                      </div>
                                    )}
                                    {isValueAllowed('ongoing_pxl_studies') && (
                                      <div className="filter-sel-data-value enrolled-subjects">
                                        {piitm.ongoing_pxl_studies}
                                      </div>
                                    )}
                                    {isValueAllowed('closed_pxl_studies') && (
                                      <div className="filter-sel-data-value enrolled-subjects">
                                        {piitm.closed_pxl_studies}
                                      </div>
                                    )}
                                    {isValueAllowed('industry_studies') && (
                                    <div className="filter-sel-data-value enrolled-subjects">
                                      {piitm.industry_studies}
                                    </div>
                                    )}
                                    {isValueAllowed('pxl_studies') && (
                                    <div className="filter-sel-data-value enrolled-subjects">
                                      {piitm.pxl_studies}
                                    </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                    {props.type === 'centers'
                    && ((props.data
                      && props.data.data
                      && props.data.data.parent_flag
                      && parse(location.search).id === rcd.id)
                      || (props.data
                        && props.data.data
                        && !props.data.data.parent_flag
                        && parse(location.search).id !== rcd.id))
                    && showPisOfCenter === rcd.id
                    && showInvestigatorSearchForParent ? (
                      <div className="pi-name-content">
                        <div className="pi-content">
                          <div className="body-analysis-section">
                            <div className="leftside-column">
                              <>
                                <div className="search-bar search-wrap hierachy-searchbar">
                                  <div className="search-bar-wrap">
                                    <div
                                      className={`search-box ${
                                        searchText.trim().length >= 3 ? 'activesearch' : ''
                                      }`}
                                    >
                                      <Search
                                        placeholder="Search for Investigator"
                                        allowClear
                                        value={searchText}
                                        loading={autoCompleteData.loading}
                                        enterButton={(
                                          <Button disabled={searchText.trim().length <= 2}>
                                            <span className="search-icon" />
                                          </Button>
                                        )}
                                        size="large"
                                        maxLength="256"
                                        onChange={(e) => onChangeInput(e, 'investigators')}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className="close-edit-search"
                                    role="presentation"
                                    onClick={() => {
                                      setSearchText('');
                                      setShowInvestigatorSearchForParent(false);
                                      setCenterForInvestigatorAddition({});
                                    }}
                                  >
                                    Cancel
                                  </div>
                                  <div className="search-dropdown-result-content">
                                    {displayDropdown ? (
                                      <OutsideClick
                                        ignoreClickWithinClass="search-bar"
                                        onClickOutside={() => {
                                          setDisplayDropdown(false);
                                        }}
                                      >
                                        <div className="search-dropdown-result">
                                          <div className="search-dropdown-result-list">
                                            <Loader
                                              loading={autoCompleteData.loading}
                                              error={autoCompleteData.error}
                                            >
                                              {renderAutosuggestOptions()}
                                            </Loader>
                                          </div>
                                        </div>
                                      </OutsideClick>
                                    ) : null}
                                  </div>
                                </div>
                              </>
                            </div>
                          </div>
                        </div>
                      </div>
                      ) : null}
                    {props.type === 'centers'
                    && ((props.data
                      && props.data.data
                      && props.data.data.parent_flag
                      && parse(location.search).id === rcd.id)
                      || (props.data
                        && props.data.data
                        && !props.data.data.parent_flag
                        && parse(location.search).id !== rcd.id))
                    && showPisOfCenter === rcd.id
                    && pisInHierarchyData.flag
                    && pisInCenterList.length ? (
                      <LazyLoadDiv
                        className="card-list scrollbar"
                        id="pis-in-center-list"
                        total={pisTotal}
                        currentTotal={(pisInCenterList || []).length}
                        loadMoreRows={() => loadMoreRows(rcd)}
                        // height="200px"
                      >
                        <div className="pi-name-content">
                          {pisInCenterList.map((piitm, idy) => {
                            return (
                              <div className="pi-content" key={idy}>
                                <div className="body-analysis-section">
                                  <div className="leftside-column">
                                    <a
                                      className={`pi-content-title ${
                                        props.type === 'investigators'
                                        && parse(location.search).id === piitm.id
                                          ? 'text-bold'
                                          : ''
                                      }`}
                                      title={piitm.name}
                                      target="_blank"
                                      href={`/search/investigatorprofile?${stringify({
                                        query: piitm.name,
                                        id: piitm.id,
                                        type: 'PI',
                                      })}`}
                                      rel="noreferrer noopener"
                                    >
                                      {piitm.name}
                                    </a>
                                  </div>
                                  <div className="table-column">
                                    <div
                                      className="table-column-sec table-column-body"
                                      onScroll={onRowScroll}
                                    >
                                      {isValueAllowed('total_popis') && (
                                        <div className="filter-sel-data-value enrolled-subjects">
                                          {piitm.total_popis}
                                        </div>
                                      )}
                                      {isValueAllowed('is_invalid') && (
                                        <div className="filter-sel-data-value enrolled-subjects">

                                          {piitm.is_invalid === false ? 'Valid' : 'Invalid'}
                                        </div>
                                      )}
                                      {isValueAllowed('alliance_type') && (
                                      <div className="filter-sel-data-value enrolled-subjects">

                                        {piitm.alliance_type ? piitm.alliance_type : '-'}
                                      </div>
                                      )}
                                      {isValueAllowed('quality') && (
                                        <div className="filter-sel-data-value quality">
                                          <Rate count={3} disabled value={piitm.quality} />
                                        </div>
                                      )}
                                      {isValueAllowed('site_reliability_class') && (
                                        <div className="filter-sel-data-value reliability">
                                          {piitm.site_reliability_class}
                                        </div>
                                      )}
                                      {isValueAllowed('site_reliability_score') && (
                                        <div className="filter-sel-data-value reliability">
                                          {piitm.site_reliability_score.toFixed(2)}
                                        </div>
                                      )}
                                      {isValueAllowed('center_sims_ids') && (
                                        <div className="filter-sel-data-value reliability">
                                          {piitm?.center_sims_ids.length ? (
                                            <ReadMore type="text" limit={30}>
                                              {piitm?.center_sims_ids.join(',')}
                                            </ReadMore>
                                          ) : (
                                            '-'
                                          )}
                                        </div>
                                      )}
                                      {isValueAllowed('survey_sent') && (
                                        <div className="filter-sel-data-value enrolled-subjects">
                                          {piitm.survey_sent}
                                        </div>
                                      )}
                                      {isValueAllowed('survey_responded') && (
                                        <div className="filter-sel-data-value enrolled-subjects">
                                          {piitm.survey_responded}
                                        </div>
                                      )}
                                      {isValueAllowed('survey_interested') && (
                                        <div className="filter-sel-data-value enrolled-subjects">
                                          {piitm.survey_interested}
                                        </div>
                                      )}
                                      {isValueAllowed('survey_not_interested') && (
                                        <div className="filter-sel-data-value enrolled-subjects">
                                          {piitm.survey_not_interested}
                                        </div>
                                      )}
                                      {isValueAllowed('startup_average') && (
                                        <div className="filter-sel-data-value enrolled-subjects">
                                          {piitm.startup_average.toFixed(2)}
                                        </div>
                                      )}
                                      {isValueAllowed('startup_factor') && (
                                        <div className="filter-sel-data-value enrolled-subjects">
                                          {piitm.startup_factor.toFixed(2)}
                                        </div>
                                      )}
                                      {isValueAllowed('recruitment_factor') && (
                                        <div className="filter-sel-data-value enrolled-subjects">
                                          {piitm.recruitment_factor.toFixed(2)}
                                        </div>
                                      )}

                                      {isValueAllowed('enrollment_subjects') && (
                                        <div className="filter-sel-data-value enrolled-subjects">
                                          {piitm.enrollment_subjects}
                                        </div>
                                      )}
                                      {isValueAllowed('ongoing_pxl_studies') && (
                                        <div className="filter-sel-data-value enrolled-subjects">
                                          {piitm.ongoing_pxl_studies}
                                        </div>
                                      )}
                                      {isValueAllowed('closed_pxl_studies') && (
                                        <div className="filter-sel-data-value enrolled-subjects">
                                          {piitm.closed_pxl_studies}
                                        </div>
                                      )}
                                      {isValueAllowed('industry_studies') && (
                                      <div className="filter-sel-data-value enrolled-subjects">
                                        {piitm.industry_studies}
                                      </div>
                                      )}
                                      {isValueAllowed('pxl_studies') && (
                                      <div className="filter-sel-data-value enrolled-subjects">
                                        {piitm.pxl_studies}
                                      </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </LazyLoadDiv>
                      ) : props.type === 'centers'
                      && ((props.data
                        && props.data.data
                        && props.data.data.parent_flag
                        && parse(location.search).id === rcd.id)
                        || (props.data
                          && props.data.data
                          && !props.data.data.parent_flag
                          && parse(location.search).id !== rcd.id))
                      && showPisOfCenter === rcd.id
                      && pisInHierarchyData.flag
                      && !pisInCenterList.length ? (
                        <div
                          className="no-data-found-msg"
                          style={{
                            height: '100px',
                            minHeight: '100px',
                            border: '1px solid #848484',
                            margin: '0 30px',
                          }}
                        >
                          No PIs are present under this center in the data.
                        </div>
                        ) : null}
                  </div>
                </div>
              )}

              {rcd.primary_organizations && rcd.primary_organizations.length ? (
                <>
                  {rcd.primary_organizations.map((recod, indexVal) => (
                    <div
                      className="run-query-content-section run-query-content-section-child"
                      key={indexVal}
                    >
                      <div className="run-query-content-card card-country-content">
                        <div className="card-content card-country-content-header">
                          <div className="body-analysis-section">
                            <div
                              className={`leftside-column ${
                                hidePIsofCenter.indexOf(recod.id) === -1
                                  ? 'dropdown-open'
                                  : 'dropdown-close'
                              }`}
                            >
                              <a
                                className={`card-content-title ${
                                  props.type === 'centers' && parse(location.search).id === recod.id
                                    ? 'text-bold'
                                    : ''
                                }`}
                                title={recod.name}
                                target="_blank"
                                href={`/search/deepdives?${stringify({
                                  query: recod.name,
                                  id: recod.id,
                                  type: recod?.parent_flag ? 'SITE_Parent' : 'SITE',
                                  currentTab: recod?.parent_flag ? 'parent_centers' : 'centers',
                                })}`}
                                rel="noreferrer noopener"
                              >
                                {recod.name}
                              </a>

                              <div
                                className={
                       recod?.center_quality === 'Compliance not preventing participation'
                       || recod?.center_quality === 'Approved with Warning'
                         ? 'compliance-flag-icon preventing'
                         : recod?.center_quality === 'Compliance preventing participation'
                           || recod?.center_quality === 'DNU'
                           ? 'compliance-flag-icon not-preventing'
                           : recod?.center_quality === 'Archived issue'
                             ? 'compliance-flag-icon archive-issue'
                             : 'heading'
                     }
                              />

                              {props.type === 'centers' && recod.pis.length ? (
                                <span
                                  className="dropdown-arrow"
                                  role="presentation"
                                  onClick={() => onCenterClick(recod)}
                                />
                              ) : null}
                            </div>
                            <div className="table-column">
                              <div
                                className="table-column-sec table-column-body"
                                onScroll={onRowScroll}
                              >
                                {isValueAllowed('total_popis') && (
                                  <div className="filter-sel-data-value enrolled-subjects">
                                    {recod.total_popis}
                                  </div>
                                )}
                                {isValueAllowed('is_invalid') && (
                                  <div className="filter-sel-data-value enrolled-subjects">
                                    {
                                      recod.is_invalid === false
                                        ? 'Valid'
                                        : 'Invalid'
                                      }
                                  </div>
                                )}
                                {isValueAllowed('alliance_type') && (
                                  <div className="filter-sel-data-value enrolled-subjects">

                                    {recod.alliance_type ? recod.alliance_type : '-'}
                                  </div>
                                )}
                                {isValueAllowed('quality') && (
                                  <div className="filter-sel-data-value quality">
                                    <Rate count={3} disabled value={recod.quality} />
                                  </div>
                                )}
                                {isValueAllowed('site_reliability_class') && (
                                  <div className="filter-sel-data-value reliability">
                                    {recod.site_reliability_class}
                                  </div>
                                )}
                                {isValueAllowed('site_reliability_score') && (
                                  <div className="filter-sel-data-value reliability">
                                    {recod.site_reliability_score.toFixed(2)}
                                  </div>
                                )}
                                {isValueAllowed('center_sims_ids') && (
                                  <div className="filter-sel-data-value reliability">
                                    {recod?.center_sims_ids.length ? (
                                      <ReadMore type="text" limit={30}>
                                        {recod?.center_sims_ids.join(',')}
                                      </ReadMore>
                                    ) : (
                                      '-'
                                    )}
                                  </div>
                                )}
                                {isValueAllowed('survey_sent') && (
                                  <div className="filter-sel-data-value enrolled-subjects">
                                    {recod.survey_sent}
                                  </div>
                                )}
                                {isValueAllowed('survey_responded') && (
                                  <div className="filter-sel-data-value enrolled-subjects">
                                    {recod.survey_responded}
                                  </div>
                                )}
                                {isValueAllowed('survey_interested') && (
                                  <div className="filter-sel-data-value enrolled-subjects">
                                    {recod.survey_interested}
                                  </div>
                                )}
                                {isValueAllowed('survey_not_interested') && (
                                  <div className="filter-sel-data-value enrolled-subjects">
                                    {recod.survey_not_interested}
                                  </div>
                                )}
                                {isValueAllowed('startup_average') && (
                                  <div className="filter-sel-data-value enrolled-subjects">
                                    {recod.startup_average.toFixed(2)}
                                  </div>
                                )}
                                {isValueAllowed('startup_factor') && (
                                  <div className="filter-sel-data-value enrolled-subjects">
                                    {recod.startup_factor.toFixed(2)}
                                  </div>
                                )}
                                {isValueAllowed('recruitment_factor') && (
                                  <div className="filter-sel-data-value enrolled-subjects">
                                    {recod.recruitment_factor.toFixed(2)}
                                  </div>
                                )}

                                {isValueAllowed('enrollment_subjects') && (
                                  <div className="filter-sel-data-value enrolled-subjects">
                                    {recod.enrollment_subjects}
                                  </div>
                                )}
                                {isValueAllowed('ongoing_pxl_studies') && (
                                  <div className="filter-sel-data-value enrolled-subjects">
                                    {recod.ongoing_pxl_studies}
                                  </div>
                                )}
                                {isValueAllowed('closed_pxl_studies') && (
                                  <div className="filter-sel-data-value enrolled-subjects">
                                    {recod.closed_pxl_studies}
                                  </div>
                                )}
                                {isValueAllowed('industry_studies') && (
                                <div className="filter-sel-data-value enrolled-subjects">
                                  {recod.industry_studies}
                                </div>
                                )}
                                {isValueAllowed('pxl_studies') && (
                                <div className="filter-sel-data-value enrolled-subjects">
                                  {recod.pxl_studies}
                                </div>
                                )}

                              </div>
                            </div>
                            {
                              !isViewOnlyAccess() && (
                                <div className="lastside-column">
                                  {props.type === 'centers' ? (
                                    <Dropdown
                                      overlay={() => primaryOrgDataMenu(recod, rcd)}
                                      trigger={['click']}
                                    >
                                      <Button
                                        className="accordion-menu"
                                        role="presentation"
                                        onClick={(e) => {
                                          e.preventDefault();
                                        }}
                                      >
                                        <Tooltip title="Kebab menu">
                                          <div className="accordion-menu-icon" />
                                        </Tooltip>
                                      </Button>
                                    </Dropdown>
                                  ) : null}
                                </div>
                              )
                            }
                          </div>
                        </div>
                        {recod.pis
                        && recod.pis.length
                        && hidePIsofCenter.indexOf(recod.id) === -1 ? (
                          <div className="pi-name-content">
                            {recod.pis.map((piitm, idy) => {
                              return (
                                <div className="pi-content" key={idy}>
                                  <div className="body-analysis-section">
                                    <div className="leftside-column">
                                      <a
                                        className={`pi-content-title ${
                                          props.type === 'investigators'
                                          && parse(location.search).id === piitm.id
                                            ? 'text-bold'
                                            : ''
                                        }`}
                                        title={piitm.name}
                                        target="_blank"
                                        href={`/search/investigatorprofile?${stringify({
                                          query: piitm.name,
                                          id: piitm.id,
                                          type: 'PI',
                                        })}`}
                                        rel="noreferrer noopener"
                                      >
                                        {piitm.name}
                                      </a>
                                    </div>
                                    <div className="table-column">
                                      <div
                                        className="table-column-sec table-column-body"
                                        onScroll={onRowScroll}
                                      >
                                        {isValueAllowed('total_popis') && (
                                          <div className="filter-sel-data-value enrolled-subjects">
                                            {piitm.total_popis}
                                          </div>
                                        )}
                                        {isValueAllowed('is_invalid') && (
                                          <div className="filter-sel-data-value enrolled-subjects">

                                            {piitm.is_invalid === false
                                              ? 'Valid'
                                              : 'Invalid'}
                                          </div>
                                        )}
                                        {isValueAllowed('alliance_type') && (
                                          <div className="filter-sel-data-value enrolled-subjects">

                                            {piitm.alliance_type ? piitm.alliance_type : '-'}
                                          </div>
                                        )}
                                        {isValueAllowed('quality') && (
                                          <div className="filter-sel-data-value quality">
                                            <Rate count={3} disabled value={piitm.quality} />
                                          </div>
                                        )}
                                        {isValueAllowed('site_reliability_class') && (
                                          <div className="filter-sel-data-value reliability">
                                            {piitm.site_reliability_class}
                                          </div>
                                        )}
                                        {isValueAllowed('site_reliability_score') && (
                                          <div className="filter-sel-data-value reliability">
                                            {piitm.site_reliability_score.toFixed(2)}
                                          </div>
                                        )}
                                        {isValueAllowed('center_sims_ids') && (
                                          <div className="filter-sel-data-value reliability">
                                            {piitm?.center_sims_ids.length ? (
                                              <ReadMore type="text" limit={30}>
                                                {piitm?.center_sims_ids.join(',')}
                                              </ReadMore>
                                            ) : (
                                              '-'
                                            )}
                                          </div>
                                        )}
                                        {isValueAllowed('survey_sent') && (
                                          <div className="filter-sel-data-value enrolled-subjects">
                                            {piitm.survey_sent}
                                          </div>
                                        )}
                                        {isValueAllowed('survey_responded') && (
                                          <div className="filter-sel-data-value enrolled-subjects">
                                            {piitm.survey_responded}
                                          </div>
                                        )}
                                        {isValueAllowed('survey_interested') && (
                                          <div className="filter-sel-data-value enrolled-subjects">
                                            {piitm.survey_interested}
                                          </div>
                                        )}
                                        {isValueAllowed('survey_not_interested') && (
                                          <div className="filter-sel-data-value enrolled-subjects">
                                            {piitm.survey_not_interested}
                                          </div>
                                        )}
                                        {isValueAllowed('startup_average') && (
                                          <div className="filter-sel-data-value enrolled-subjects">
                                            {piitm.startup_average.toFixed(2)}
                                          </div>
                                        )}
                                        {isValueAllowed('startup_factor') && (
                                          <div className="filter-sel-data-value enrolled-subjects">
                                            {piitm.startup_factor.toFixed(2) }
                                          </div>
                                        )}
                                        {isValueAllowed('recruitment_factor') && (
                                          <div className="filter-sel-data-value enrolled-subjects">
                                            {piitm.recruitment_factor.toFixed(2)}
                                          </div>
                                        )}

                                        {isValueAllowed('enrollment_subjects') && (
                                          <div className="filter-sel-data-value enrolled-subjects">
                                            {piitm.enrollment_subjects}
                                          </div>
                                        )}
                                        {isValueAllowed('ongoing_pxl_studies') && (
                                          <div className="filter-sel-data-value enrolled-subjects">
                                            {piitm.ongoing_pxl_studies}

                                          </div>
                                        )}
                                        {isValueAllowed('closed_pxl_studies') && (
                                          <div className="filter-sel-data-value enrolled-subjects">
                                            {piitm.closed_pxl_studies}
                                          </div>
                                        )}
                                        {isValueAllowed('industry_studies') && (
                                          <div className="filter-sel-data-value enrolled-subjects">
                                            {piitm.industry_studies}
                                          </div>
                                        )}
                                        {isValueAllowed('pxl_studies') && (
                                        <div className="filter-sel-data-value enrolled-subjects">
                                          {piitm.pxl_studies}
                                        </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          ) : null}
                        {showPisOfCenter === recod.id && showInvestigatorSearch ? (
                          <div className="pi-name-content">
                            <div className="pi-content">
                              <div className="body-analysis-section">
                                <div className="leftside-column">
                                  <>
                                    <div className="search-bar search-wrap hierachy-searchbar">
                                      <div className="search-bar-wrap" style={{ maxHeight: 'inherit' }}>
                                        <div
                                          className={`search-box ${
                                            searchText.trim().length >= 3 ? 'activesearch' : ''
                                          }`}
                                        >
                                          <Search
                                            placeholder="Search for Investigator"
                                            allowClear
                                            value={searchText}
                                            loading={autoCompleteData.loading}
                                            enterButton={(
                                              <Button disabled={searchText.trim().length <= 2}>
                                                <span className="search-icon" />
                                              </Button>
                                            )}
                                            size="large"
                                            maxLength="256"
                                            onChange={(e) => onChangeInput(e, 'investigators')}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        className="close-edit-search"
                                        role="presentation"
                                        onClick={() => {
                                          setSearchText('');
                                          setShowInvestigatorSearch(false);
                                          setCenterForInvestigatorAddition({});
                                        }}
                                      >
                                        Cancel
                                      </div>
                                      <div className="search-dropdown-result-content">
                                        {displayDropdown ? (
                                          <OutsideClick
                                            ignoreClickWithinClass="search-bar"
                                            onClickOutside={() => {
                                              setDisplayDropdown(false);
                                            }}
                                          >
                                            <div className="search-dropdown-result">
                                              <div className="search-dropdown-result-list">
                                                <Loader
                                                  loading={autoCompleteData.loading}
                                                  error={autoCompleteData.error}
                                                >
                                                  {renderAutosuggestOptions()}
                                                </Loader>
                                              </div>
                                            </div>
                                          </OutsideClick>
                                        ) : null}
                                      </div>
                                    </div>
                                  </>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {showPisOfCenter === recod.id
                        && pisInHierarchyData.flag
                        && pisInCenterList.length ? (
                          <LazyLoadDiv
                            className="card-list scrollbar"
                            id="pis-in-center-list"
                            total={pisTotal}
                            currentTotal={(pisInCenterList || []).length}
                            loadMoreRows={() => loadMoreRows(recod)}
                            // height="200px"
                          >
                            <div className="pi-name-content">
                              {pisInCenterList.map((piitm, idy) => {
                                return (
                                  <div className="pi-content" key={idy}>
                                    <div className="body-analysis-section">
                                      <div className="leftside-column">
                                        <a
                                          className={`pi-content-title ${
                                            props.type === 'investigators'
                                            && parse(location.search).id === piitm.id
                                              ? 'text-bold'
                                              : ''
                                          }`}
                                          title={piitm.name || piitm.pi_name}
                                          target="_blank"
                                          href={`/search/investigatorprofile?${stringify({
                                            query: piitm.name || piitm.pi_name,
                                            id: piitm.id,
                                            type: 'PI',
                                          })}`}
                                          rel="noreferrer noopener"
                                        >
                                          {piitm.name || piitm.pi_name}
                                        </a>
                                      </div>
                                      <div className="table-column">
                                        <div
                                          className="table-column-sec table-column-body"
                                          onScroll={onRowScroll}
                                        >
                                          {isValueAllowed('total_popis') && (
                                            <div className="filter-sel-data-value enrolled-subjects">
                                              {piitm.total_popis}
                                            </div>
                                          )}
                                          {isValueAllowed('is_invalid') && (
                                            <div className="filter-sel-data-value enrolled-subjects">
                                              {piitm.is_invalid === false
                                                ? 'Valid'
                                                : 'Invalid'}
                                            </div>
                                          )}
                                          {isValueAllowed('alliance_type') && (
                                            <div className="filter-sel-data-value enrolled-subjects">

                                              {piitm.alliance_type ? piitm.alliance_type : '-'}
                                            </div>
                                          )}
                                          {isValueAllowed('quality') && (
                                            <div className="filter-sel-data-value quality">
                                              <Rate count={3} disabled value={piitm.quality} />
                                            </div>
                                          )}
                                          {isValueAllowed('site_reliability_class') && (
                                            <div className="filter-sel-data-value reliability">
                                              {piitm?.site_reliability_class}
                                            </div>
                                          )}
                                          {isValueAllowed('site_reliability_score') && (
                                            <div className="filter-sel-data-value reliability">
                                              {piitm?.site_reliability_score?.toFixed(2)}
                                            </div>
                                          )}
                                          {isValueAllowed('center_sims_ids') && (
                                            <div className="filter-sel-data-value reliability">
                                              {piitm?.center_sims_ids?.length ? (
                                                <ReadMore type="text" limit={30}>
                                                  {piitm?.center_sims_ids?.join(',')}
                                                </ReadMore>
                                              ) : (
                                                '-'
                                              )}
                                            </div>
                                          )}
                                          {isValueAllowed('survey_sent') && (
                                            <div className="filter-sel-data-value enrolled-subjects">
                                              {piitm?.survey_sent}
                                            </div>
                                          )}
                                          {isValueAllowed('survey_responded') && (
                                            <div className="filter-sel-data-value enrolled-subjects">
                                              {piitm?.survey_responded}
                                            </div>
                                          )}
                                          {isValueAllowed('survey_interested') && (
                                            <div className="filter-sel-data-value enrolled-subjects">
                                              {piitm?.survey_interested}
                                            </div>
                                          )}
                                          {isValueAllowed('survey_not_interested') && (
                                            <div className="filter-sel-data-value enrolled-subjects">
                                              {piitm?.survey_not_interested}
                                            </div>
                                          )}
                                          {isValueAllowed('startup_average') && (
                                            <div className="filter-sel-data-value enrolled-subjects">
                                              {piitm?.startup_average?.toFixed(2)}
                                            </div>
                                          )}
                                          {isValueAllowed('startup_factor') && (
                                            <div className="filter-sel-data-value enrolled-subjects">
                                              {piitm?.startup_factor?.toFixed(2)}
                                            </div>
                                          )}
                                          {isValueAllowed('recruitment_factor') && (
                                            <div className="filter-sel-data-value enrolled-subjects">
                                              {piitm?.recruitment_factor?.toFixed(2)}
                                            </div>
                                          )}

                                          {isValueAllowed('enrollment_subjects') && (
                                            <div className="filter-sel-data-value enrolled-subjects">
                                              {piitm?.enrollment_subjects}
                                            </div>
                                          )}
                                          {isValueAllowed('ongoing_pxl_studies') && (
                                            <div className="filter-sel-data-value enrolled-subjects">
                                              {piitm?.ongoing_pxl_studies}

                                            </div>
                                          )}
                                          {isValueAllowed('closed_pxl_studies') && (
                                            <div className="filter-sel-data-value enrolled-subjects">
                                              {piitm?.closed_pxl_studies}
                                            </div>
                                          )}
                                          {isValueAllowed('industry_studies') && (
                                          <div className="filter-sel-data-value enrolled-subjects">
                                            {piitm?.industry_studies}
                                          </div>
                                          )}
                                          {isValueAllowed('pxl_studies') && (
                                          <div className="filter-sel-data-value enrolled-subjects">
                                            {piitm?.pxl_studies }
                                          </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </LazyLoadDiv>
                          ) : showPisOfCenter === recod.id
                          && pisInHierarchyData.flag
                          && !pisInCenterList.length ? (
                            <div
                              className="no-data-found-msg"
                              style={{
                                height: '100px',
                                minHeight: '100px',
                                border: '1px solid #848484',
                                margin: '0 30px',
                              }}
                            >
                              No PIs are present under this center in the data.
                            </div>
                            ) : null}
                      </div>
                    </div>
                  ))}
                </>
              ) : null}
            </div>
          ))}
      </div>
    );
  };

  const exportData = (val) => {
    let params;
    if (val === 'complete') {
      params = {
        id: parse(location.search).id,
        alliance_type: props.allianceType,
        downloading: true,
      };
    }
    const filename = `Hierarchy_${
      parse(location.search).id
    }_Exported_Data_${val}${new Date()}.xlsx`;

    Axios({
      url: `${process.env.apiUrl}v0/institution/getParentSiteHierarchy?${stringify(params)}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        Authorization: getCookie('accessToken'),
        'Content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'Access-Control-Allow-Origin': `${process.env.applicationUrl}`,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        return null;
      })
      .catch((error) => {
        return error;
      });
  };

  return (
    <div className="investigator-hierarchy-wrap">
      <Loader
        loading={parentInstitutionHierarchyData.loading}
        error={parentInstitutionHierarchyData.error}
      >
        {parentInstitutionHierarchyData.flag
        && getHierarchyData.data
        && getHierarchyData.data.length ? (
          <div className="investigator-hierarchy-wrap-card card">
            <div className="hierarchy-sub-header">
              <Popover
                overlayClassName="show-hide-popover"
                trigger="click"
                // placement="right"
                content={(
                  <div>
                    <div className="show-hide-btns">
                      <Button onClick={onApplyShowHideClick} type="primary">
                        Apply
                      </Button>
                      <Button onClick={onApplyShowHideCancelClick} type="primary">
                        Cancel
                      </Button>
                    </div>
                  </div>
                )}
                title={(
                  <div>
                    {filterHeader.map((itm) => {
                      return (
                        <div className="compare-row-content-data-value">
                          <Checkbox
                            checked={checkedValueList.indexOf(itm.key) !== -1}
                            onChange={(e) => onChange(e, itm.key, itm.header_name)}
                          >
                            {itm.header_name}
                          </Checkbox>
                        </div>
                      );
                    })}
                  </div>
                )}
                open={visible}
                onOpenChange={(visible1) => {
                  setVisible(visible1);
                }}
              >
                <div className="show-hide-btn-sec">
                  <Button type="primary" onClick={handleVisibleChange}>
                    Show/Hide columns
                  </Button>
                </div>
              </Popover>
              {
                !isViewOnlyAccess() && (
                  <div>
                    <div
                      className="export-to-csv-btn-trials-at-institute"
                      role="presentation"
                      onClick={() => {
                        exportData('complete');
                      }}
                    />
                  </div>
                )
              }
              {/* <div className="graph-section-results-export">
                  <Tooltip title="Export">
                    <div className="export-to-csv-btn">
                      <Select
                        className="export-to-csv-btn-select"
                        style={{
                          width: 120,
                        }}
                        disabled={downloading}
                        onChange={(val) => exportData(val)}
                        value={exportVal}
                      >
                        <Option value="complete">Export all data</Option>
                      </Select>
                    </div>
                  </Tooltip>
                </div> */}

              {/* <div className="hierarchy-sub-header-content">
                  <div className="hierarchy-sub-header-content-title"> Total PIs: {getHierarchyData?.total_pis} </div>
                </div> */}
              <div
                className="show-doc-outof show-doc-outof-w-relative"
              >
                <div className="show-doc-data">
                  Showing<span className="show-count">{getHierarchyData?.total_pis}</span>PIs ,
                  <span className="show-count">
                    {getHierarchyData?.total_child_and_alliance_sites}
                  </span>
                  Centers
                </div>
              </div>
            </div>
            <div className="project-data-wrap-content-header-filter-value">
              <div className="header-analysis-section">
                <div className="leftside-column" />
                <div className="table-column">{renderHeader()}</div>
                <div className="lastside-column" />
              </div>
            </div>
            <div className="investigator-hierarchy-wrap-card-content">
              {parentInstitutionHierarchyData.flag
                && getHierarchyData.data
                && getHierarchyData.data.map((record, recordIndex) => renderHirerchyData(record, recordIndex))}
            </div>
          </div>
          ) : (
            <div className="no-data-found-msg">No relevant data found</div>
          )}
      </Loader>

      <>
        <Modal
          style={{ marginTop: '81px', width: '100%' }}
          title="Remove center from hierarchy?"
          visible={showRemoveCenterConfirm}
          className="del-modal-wrapper"
          onCancel={handleRemoveCenterCancel}
          destroyOnClose
          footer={null}
        >
          <Loader
            loading={removeChildCenterFromHierarchyResponse.loading}
            error={removeChildCenterFromHierarchyResponse.error}
          >
            <div className="delete-modal-card">
              {`Are you sure you want to remove "${childCenterForRemoval.name}" from "${parentForChildRemoval.name}'s" hierarchy?`}
            </div>
            <div className="footer-btn">
              <Button
                className="delete-button"
                type="primary"
                onClick={() => onRemoveCenterClick()}
              >
                Yes
              </Button>
            </div>
          </Loader>
        </Modal>
        <Modal
          style={{ marginTop: '81px', width: '100%' }}
          title="Add center to hierarchy?"
          open={isConfirmationModalOpen}
          className="del-modal-wrapper"
          onCancel={() => {
            setChildCenterForAddition({});
            setIsConfirmationModalOpen(false);
          }}
          destroyOnClose
          footer={null}
        >
          <Loader
            loading={updateSiteResponse.loading}
            error={updateSiteResponse.error}
          >
            <div className="delete-modal-card">
              {`Are you sure you want to add "${childCenterForAddition.name}" to "${parentForChildAddition.name}'s" hierarchy?`}
            </div>
            <div className="footer-btn">
              <Button className="delete-button" type="primary" onClick={() => onAddCenterClick()}>
                Yes
              </Button>
            </div>
          </Loader>
        </Modal>
        <Modal
          style={{ marginTop: '81px', width: '100%' }}
          title="Change parent in hierarchy?"
          visible={showParentChangeConfirm}
          className="del-modal-wrapper"
          onCancel={() => {
            setParentCenterForChange({});
            setShowParentChangeConfirm(false);
          }}
          destroyOnClose
          footer={null}
        >
          <Loader
            loading={changeParentInHierarchyResponse.loading}
            error={changeParentInHierarchyResponse.error}
          >
            <div className="delete-modal-card">
              {`Are you sure you want to change parent to "${parentCenterForChange.name}" in the hierarchy?`}
            </div>
            <div className="footer-btn">
              <Button
                className="delete-button"
                type="primary"
                onClick={() => onChangeParentClick()}
              >
                Yes
              </Button>
            </div>
          </Loader>
        </Modal>
        <Modal
          style={{ marginTop: '81px', width: '100%' }}
          title="Add investigator in the hierarchy?"
          visible={showInvestigatorAddConfirm}
          className="del-modal-wrapper"
          onCancel={() => {
            setInvestigatorForAddition({});
            setShowInvestigatorAddConfirm(false);
          }}
          destroyOnClose
          footer={null}
        >
          <Loader loading={updatePiResponse.loading || investigatorProfileData.loading} error={updatePiResponse.error}>
            <div className="delete-modal-card">
              {`Are you sure you want to add "${investigatorForAddition.name}" to "${
                centerForInvestigatorAddition.parent_name
                  ? centerForInvestigatorAddition.parent_name
                  : centerForInvestigatorAddition.name
              }" in the hierarchy?`}
            </div>
            <div className="footer-btn">
              <Button
                className="delete-button"
                type="primary"
                onClick={() => onAddInvestigator()}
                disabled={!investigatorProfileData.flag}
              >
                Yes
              </Button>
            </div>
          </Loader>
        </Modal>
        <Modal
          style={{ width: '100%', height: 'calc(100vh - 200px)' }}
          bodyStyle={{ overflowX: 'scroll' }}
          title={(
            <div className="header-wrapper">
              <div className="modal-title">
                <span className="org-icon" />
                Current primary center
              </div>
              <div className="project-wrapper">
                {centerForInvestigatorRemoval.parent_name
                  ? centerForInvestigatorRemoval.parent_name
                  : centerForInvestigatorRemoval.name}
              </div>
            </div>
          )}
          visible={showChangePOModal}
          onCancel={() => {
            setShowChangePOModal(false);
            setInvestigatorForPOChange({});
          }}
          className="modal-wrapper change-pi-po-modal"
          destroyOnClose
          footer={null}
        >
          <Loader loading={updatePiResponse.loading} error={updatePiResponse.error}>
            <div className="modal-content">
              <>
                <div className="searchbar-add-title">Change primary center to</div>
                <div className="search-bar search-wrap hierachy-searchbar">
                  <div className="search-bar-wrap">
                    <div
                      className={`search-box ${
                        searchText.trim().length >= 3 ? 'activesearch' : ''
                      }`}
                    >
                      <Search
                        placeholder="Search for Center"
                        allowClear
                        value={searchText}
                        loading={autoCompleteData.loading}
                        enterButton={(
                          <Button disabled={searchText.trim().length <= 2}>
                            <span className="search-icon" />
                          </Button>
                        )}
                        size="large"
                        maxLength="256"
                        onChange={(e) => onChangeInput(e, 'centers')}
                      />
                    </div>
                  </div>
                  <div className="search-dropdown-result-content">
                    {displayDropdown ? (
                      <OutsideClick
                        ignoreClickWithinClass="search-bar"
                        onClickOutside={() => {
                          setDisplayDropdown(false);
                        }}
                      >
                        <div className="search-dropdown-result">
                          <div className="search-dropdown-result-list">
                            <Loader
                              loading={autoCompleteData.loading}
                              error={autoCompleteData.error}
                            >
                              {renderAutosuggestOptions()}
                            </Loader>
                          </div>
                        </div>
                      </OutsideClick>
                    ) : null}
                  </div>
                </div>
              </>
            </div>
            <div className="share-footer-btn">
              <Button
                className="done-button"
                type="primary"
                onClick={() => onChangePODone()}
                disabled={!centerForInvestigatorAddition.id}
              >
                Done
              </Button>
            </div>
          </Loader>
        </Modal>
      </>
    </div>
  );
};

export default ParentCenterHierarchy;
